export default defineNuxtRouteMiddleware(async (to, from) => {
  // skip middleware on server entirely
  if (import.meta.server) return;

  if (to.fullPath.split("/").includes("auth")) {
    return;
  }

  if (!useCustomerStore().isLoggedIn) {
    try {
      await useCustomerStore().getCustomer();
      if (!useCustomerStore().isLoggedIn) {
        return navigateTo("/auth");
      }
    } catch (error) {
      return navigateTo("/auth");
    }
  }

  return;
});
